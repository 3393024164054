<template v-if="uuid">
	<CheckoutApprove :uuid="uuid" :details="orderData" />
</template>

<script>
import CheckoutApprove from "@/view/module/approve/CheckoutApprove";
import { GetOrder } from "@/core/lib/cart.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
export default {
	name: "checkout-approve",
	data() {
		return {
			uuid: null,
			orderData: new Object(),
		};
	},
	components: {
		CheckoutApprove,
	},
	created() {
		const { uuid } = this.$route.params;
		this.uuid = uuid;
		if (!this.uuid) {
			this.goBack();
		}
		this.getOrder();
	},
	methods: {
		getOrder() {
			const _this = this;
			GetOrder(this.uuid)
				.then((data) => {
					_this.orderData = data;
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
};
</script>
